<template>
  <div class="flex items-center gap-x-[0.1rem]">
    <button
      :id="id"
      :type="type"
      @click="$emit('click', $event)"
      class="bg-blue-600 h-8 hover:bg-blue-700 rounded-tl-[0.3rem] rounded-bl-[0.3rem] px-[0.75rem] py-[0.5rem] text-xs !leading-none text-white m-0 transition-all ease-in-out  justify-center text-center font-medium font-poppins items-center gap-2.5 inline-flex">
      <slot name="label"></slot>
      <slot name="loader"></slot>
    </button>
    <div class="manage-dropdown shortcut_menu_dropdown">
      <b-dropdown
        offset="25, 0"
        left
        class="dropdown-menu-left hide_caret default_style_dropdown"
        no-caret
      >
        <div class="dropdown_header h-8 bg-blue-600 hover:bg-blue-700 rounded-tr-[0.3rem] rounded-br-[0.3rem] px-[0.75rem] py-[0.5rem] text-xs leading-none text-white m-0 transition-all ease-in-out  justify-center text-center font-medium font-poppins items-center gap-2.5 inline-flex" slot="button-content">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-[0.625rem] h-[0.375rem]" viewBox="0 0 10 6" fill="none">
            <path d="M1 1L5 5L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <transition name="fade">
          <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white font-poppins rounded-bl-lg rounded-br-lg rounded-tr-lg">
            <li @click="$bvModal.show('bulk-import-modal-v2'); $emit('update', true)" class="cursor-pointer group hover:rounded-tl-lg hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.59995 0.799219C8.59995 0.467848 8.33132 0.199219 7.99995 0.199219C7.66858 0.199219 7.39995 0.467848 7.39995 0.799219V8.95069L4.42421 5.97495C4.1899 5.74064 3.81 5.74064 3.57569 5.97495C3.34137 6.20927 3.34137 6.58917 3.57569 6.82348L7.57569 10.8235C7.81 11.0578 8.1899 11.0578 8.42422 10.8235L12.4242 6.82348C12.6585 6.58917 12.6585 6.20927 12.4242 5.97495C12.1899 5.74064 11.81 5.74064 11.5757 5.97495L8.59995 8.95069V0.799219ZM0.799951 9.79922C1.13132 9.79922 1.39995 10.0678 1.39995 10.3992V13.5992C1.39995 13.8644 1.50531 14.1188 1.69284 14.3063C1.88038 14.4939 2.13473 14.5992 2.39995 14.5992H13.6C13.8652 14.5992 14.1195 14.4939 14.3071 14.3063C14.4946 14.1188 14.6 13.8644 14.6 13.5992V10.3992C14.6 10.0678 14.8686 9.79922 15.2 9.79922C15.5313 9.79922 15.8 10.0678 15.8 10.3992V13.5992C15.8 14.1827 15.5682 14.7423 15.1556 15.1549C14.743 15.5674 14.1834 15.7992 13.6 15.7992H2.39995C1.81647 15.7992 1.2569 15.5674 0.844316 15.1549C0.431736 14.7423 0.199951 14.1827 0.199951 13.5992V10.3992C0.199951 10.0678 0.46858 9.79922 0.799951 9.79922Z" fill="#3C4549"/>
              </svg>
              <p>Import CSV</p>
            </li>
            <li @click="$bvModal.show('bulk-import-modal-v2'); $emit('update', false)" class="cursor-pointer group hover:bg-[#F4F6FA]  px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-[1.125rem] h-[1.125rem]" viewBox="0 0 18 18" fill="none">
                <path d="M7.49146 9.75454C7.81537 10.1876 8.22864 10.5459 8.70321 10.8052C9.17778 11.0645 9.70257 11.2186 10.242 11.2573C10.7814 11.2959 11.3228 11.2181 11.8295 11.0291C12.3361 10.8401 12.7962 10.5443 13.1786 10.1618L15.4413 7.89907C16.1283 7.18779 16.5084 6.23516 16.4998 5.24634C16.4913 4.25751 16.0946 3.31163 15.3954 2.6124C14.6962 1.91317 13.7503 1.51655 12.7615 1.50796C11.7726 1.49936 10.82 1.87949 10.1087 2.56646L8.81141 3.85624" stroke="#3C4549" stroke-width="1.29767" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5085 8.24479C10.1846 7.81175 9.77135 7.45344 9.29678 7.19416C8.8222 6.93487 8.29742 6.78069 7.75801 6.74206C7.21861 6.70343 6.67721 6.78125 6.17053 6.97026C5.66385 7.15927 5.20375 7.45503 4.82142 7.83749L2.55865 10.1003C1.87168 10.8115 1.49155 11.7642 1.50014 12.753C1.50874 13.7418 1.90536 14.6877 2.60459 15.3869C3.30381 16.0862 4.2497 16.4828 5.23852 16.4914C6.22734 16.5 7.17998 16.1199 7.89125 15.4329L9.18104 14.1431" stroke="#3C4549" stroke-width="1.29767" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>Bulk Create</p>
            </li>
          </ul>
        </transition>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ButtonWithDropdown',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    bg: {
      type: String,
      default: 'bg-blue-600',
    },
    hover: {
      type: String,
      default: 'bg-blue-700',
    },
    width: {
      type: String,
      default: '4',
    },
    height: {
      type: String,
      default: '12',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    }
  },
  computed: {
    buttonSize() {
      if(this.customClass) {
        return `${this.customClass} text-sm leading-[0.875rem]`
      }

      if(this.buttonClass === 'btn-lg') {
        return `px-[2rem] py-[1rem] min-w-[7.063rem] h-[2.875rem] text-sm leading-[0.875rem]`
      }

      if(this.buttonClass === 'btn-md') {
        return `px-[1rem] py-[0.75rem] text-sm leading-[0.875rem] text-white`
      }

      if(this.buttonClass === 'btn-md-outline') {
        return `px-[0.75rem] py-[0.75rem] text-sm leading-[0.875rem] text-gray-500`
      }

      if(this.buttonClass === 'btn-sm') {
        return `px-[1rem] py-[0.5rem] text-xs leading-none text-white`
      }

    }
  }
}
</script>
